import PropTypes from 'prop-types';

import React from 'react';

import Avatar from '@components/avatar/Avatar';
import { setCursorSupport } from '@functions/supportHandler';

class CorporationInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { id, logo, name, className = '', short } = this.props;

        const supportProps =
            short &&
            setCursorSupport(
                {
                    _id: `corporationInfo-${id}`,
                    content: `Компания<br/>${name}`,
                    dir: 'top',
                },
                { targetClassName: '.manualCorporationInfo', className: '' },
            );

        return name ? (
            <div
                className={`manualCorporationInfo ${className} ${short ? '_short' : ''}`}
                {...supportProps}
            >
                <div className="manualCorporationInfo__logo">
                    <Avatar image={logo?.fullSrc} firstSymb={name?.[0]} className="_grey" />
                </div>

                {!short && <div className="manualCorporationInfo__name">{name}</div>}
            </div>
        ) : null;
    }
}

export default CorporationInfo;

CorporationInfo.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.object,
    name: PropTypes.string,
    short: PropTypes.bool,
};
