import React from 'react';

import TagI from './types.ts';

class Tag extends React.Component<TagI['props'], TagI['state']> implements TagI {
    constructor(props: TagI['props']) {
        super(props);
        this.state = {};
    }

    render() {
        const { color, children, isFull } = this.props;
        const style = isFull ? { background: color, color: '#fff' } : { borderColor: color, color };

        return (
            <div className="v2tag" style={style}>
                {children}
            </div>
        );
    }
}

export default Tag;
