import axios from 'axios';

import getHeaders from '../functions/getHeaders';

export default function setCommentsChat({ id, comments }) {
    return new Promise((resolve, reject) => {
        axios
            .put(
                `${process.env.REACT_APP_API}/chat`,
                { id, content: comments, type: 'comments' },
                {
                    headers: getHeaders(),
                },
            )
            .then((res) => {
                const { success } = res.data;

                if (success) {
                    resolve();
                } else {
                    reject();
                }
            }, reject);
    });
}
