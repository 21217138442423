const chatSupportMessage = {
    title: 'Системное сообщение',
    description: `Введите сообщение в поле ниже. Его&nbsp;<b>не&nbsp;будет видно</b> собеседнику`,
    buttonText: 'Отправить',
    textMessage: {
        support: 'Сообщение',
    },
} as const;

export default chatSupportMessage;
