import AlertI from '../../types';

const setCardNumberFromChat = {
    title: 'Подтвердите замену карты',
    description(this: AlertI) {
        const info = this.state.info as { name: string; number: string };

        return `Заменить номер карты у исполнителя <b>${info.name}</b> на <b>${info.number}</b>?`;
    },
    buttonText: 'Заменить',
} as const;

export default setCardNumberFromChat;
