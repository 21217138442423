import React from 'react';

import CursorListsItem from '@components/cursorListsItem/CursorListsItem';
import EditBlockI from '@components/editBlock/types';
import { FilterBlockT } from '@global/types';

type ParamsT = {
    change: EditBlockI['change'];
    index: number;
    value?: string[];
    updatedKey?: string;
} & Pick<FilterBlockT, 'selectList'>;

export default function Select(props: ParamsT): React.ReactNode {
    const { selectList, index, change, value, updatedKey } = props;

    return (
        <div className="v2filter__blockSelect">
            <CursorListsItem
                className="_inFilter"
                name={selectList!}
                callback={(id) => {
                    change({
                        [`${index}.value`]: id,
                    });
                }}
                inFilter={true}
                currents={value || []}
                multi={true}
                withDrop={true}
                updatedKey={updatedKey}
            />
        </div>
    );
}
