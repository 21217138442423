import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Tag from '@components/crm/tag/Tag.tsx';

import getHeaders from '../../functions/getHeaders';
import setNotification from '../../functions/setNotification';

import ListAbsoluteMain from '../ListAbsoluteMain.jsx';

class WindowTags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.save = this.save.bind(this);
        this.renderTag = this.renderTag.bind(this);

        this.parent = React.createRef();
    }

    save({ tags }) {
        const { chat } = this.props;

        return new Promise((resolve, reject) => {
            if (chat) {
                const { _id: id } = chat;

                axios
                    .patch(
                        `${process.env.REACT_APP_API}/chat`,
                        { id, action: 'set-tags', tags },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (success) {
                                setNotification({ notification: 'success-change-info' });
                            }

                            resolve();
                        },
                        () => null,
                    );
            } else {
                reject();
            }
        });
    }

    renderTag({ item: tag }) {
        const { name } = tag;

        return (
            <div className="chatWindowTags__label">
                <Tag color={tag.colorOfGroup} isFull={true}>
                    {name}
                </Tag>
            </div>
        );
    }

    sortTags(tags = []) {
        return JSON.parse(JSON.stringify(tags)).sort((a, b) => {
            const textA = a.name.toUpperCase();
            const textB = b.name.toUpperCase();

            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    }

    getTags() {
        const { chat } = this.props;

        let tags = JSON.parse(JSON.stringify(chat?.tagsInfo || []));
        const max = 6;

        if (tags.length > max) {
            tags = [
                ...tags.filter((tag, key) => key <= max),
                {
                    id: `more${tags.length}`,
                    name: `Ещё ${tags.length - max}`,
                    colorOfGroup: '#3e6aed',
                },
            ];
        }

        return tags;
    }

    componentDidMount() {
        const { setContentHeight } = this.props;

        setContentHeight();
    }

    render() {
        // const { updatedTagsKey } = this.state;
        const { isShow } = this.props;
        const tags = this.getTags();

        return (
            <div ref={this.parent} className="chatWindowTags">
                <div className="chatWindowTags__inner _row">
                    <div className="chatWindowTags__labels">
                        <ListAbsoluteMain
                            className="chatWindowTags__labelsInner"
                            items={tags}
                            renderItem={this.renderTag}
                            classNameItem="chatWindowTags__label"
                            prop="id"
                            paramsParent={{ width: true }}
                            keyRender={isShow}
                            sort={this.sortTags}
                            minHeight={19}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(WindowTags);

WindowTags.propTypes = {
    chat: PropTypes.object,
    setContentHeight: PropTypes.func,
    isShow: PropTypes.bool,
    isDisabled: PropTypes.bool,
};
