const info = {
    executors: { title: 'Фильтр исполнителей' },
    modelLogs: { title: 'Фильтр логов' },
    joins: { title: 'Фильтр приглашений' },
    corporationBalances: { title: 'Фильтр операций' },
    users: { title: 'Фильтр сотрудников' },
    corporationReports: { title: 'Фильтр отчётов' },
    corporations: { title: 'Фильтр компаний' },
    pays: { title: 'Фильтр актов' },
    paysGroups: { title: 'Фильтр реестров' },
    joinsContracts: { title: 'Фильтр договоров' },
    joinsScripts: { title: 'Фильтр сценариев' },
    joinsTemplates: { title: 'Фильтр шаблонов' },
    joinsDocs: { title: 'Фильтр документов' },
    executorsMvds: { title: 'Фильтр уведомлений МВД' },
    executorsTaxes: { title: 'Фильтр налоговой копилки' },
    executorsLimits: { title: 'Фильтр лимитов' },
    tags: { title: 'Фильтр тегов' },
    listTags: { title: 'Фильтр тегов' },
    executorsBalances: { title: 'Фильтр балансов' },
    chatMailings: { title: 'Фильтр рассылок' },
    chatTemplates: { title: 'Фильтр шаблонов' },
    popupChatTemplates: { title: 'Фильтр шаблонов' },
    payProjects: { title: 'Фильтр проектов' },
    listExecutors: { title: 'Фильтр исполнителей' },
    listUsers: { title: 'Фильтр сотрудников' },
    corporationRoles: { title: 'Фильтр ролей' },
    docs: { title: 'Фильтр документов' },
    cities: { title: 'Фильтр городов' },
    countries: { title: 'Фильтр стран' },
    executorTypes: { title: 'Фильтр категорий' },
    chat: { title: 'Фильтр чатов' },
} as const;

export default info;
